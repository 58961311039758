import { Link } from "gatsby"
import React from "react"

import addToMailchimp from 'gatsby-plugin-mailchimp'

import './subscribe-form.scss'

class SubscribeForm extends React.Component {
  state = {
    email: '',
    error: null,
    message: null
  }

  handleChange(e) {
    const userEmail = e.target.value;
      this.setState(() => ({
        [e.target.name]: userEmail,
        error: null
      }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const userEmail = this.state.email;
    const sub = await addToMailchimp(userEmail);
    console.log(sub)
    this.setState(() => ({
      message: sub.result
    }))
  }

  render() {
    
    return (
      <form className='subscribe-form' name="mc-embedded-subscribe-form" onSubmit={this.handleSubmit}>
        <input type="email" placeholder="Newsletter" value={this.state.email} name="email" onChange={(e) => this.handleChange(e)} />
        <button className="mt-2">subscribe</button>
        {this.state.error && (
          <p>{this.state.error}</p>
        )}
        {this.state.message && (
          <p>{this.state.message}</p>
        )}
      </form>
    )
  }
}

export default SubscribeForm;

