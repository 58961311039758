import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const triggerGA = (category, label) => {
  if (category, label) {
    trackCustomEvent({
      category: category,
      action: "Click",
      label: label
    })
  }
}

const EssentialLinks = ({gaCategory}) => {
  return (
    <>
      <p className='link' style={{borderColor: '#629aa9'}}>
              <a 
                  href="https://silvaelectronics.bandcamp.com/"
                  style={{color: '#629aa9'}} 
                  onClick={() => triggerGA(gaCategory, 'bandcamp')}
                >
                  Bandcamp
                </a>
            </p>
            <p className='link' style={{borderColor: '#FE5000'}}>
              <a 
                  href="https://soundcloud.com/silvaelectronics/"
                  style={{color: '#FE5000'}}
                  onClick={() => triggerGA(gaCategory, 'soundcloud')}
                >
                  SoundCloud
                </a>
            </p>
            <p className='link' style={{borderColor: '#1DB954'}}>
              <a 
                  href="https://open.spotify.com/playlist/11glIMvFlbpftSVUPrVLB0?si=8356319043d240c9"
                  style={{color: '#1DB954'}}
                  onClick={() => triggerGA(gaCategory, 'spotify')}
                >
                  Spotify
                </a>
            </p>
            <p className='link' style={{borderColor: '#c13584'}}>
              <a 
                  href="https://www.instagram.com/silvaelectronics/"
                  style={{color: '#c13584'}}
                  onClick={() => triggerGA(gaCategory, 'instagram')}
                >
                  Instagram
                </a>
            </p>
    </>
  )
}

export default EssentialLinks;